.handicap-table
{
    background-color: #d82c2c;
    border: #440000 1px solid;
    color: #fff;
    font-size: 15pt;
}

.handicap-table .row:nth-of-type(odd) {
    background-color: rgba(0,0,0,.08);
}

.handicap-table .row .row:nth-of-type(odd) {
    background-color: rgba(0,0,0,0);
}

.handicap-table .row{
    
    margin-left: 0px;
    margin-right: 0px;
    padding: 6px;
}

.handicap-table .row .row{
    border: none;
    padding: 1px;
}

.handicap-table-header
{
    background-color: #911414;
}

.handicap-detail-summary{
    min-width: 70%;
    border: #440000 1px solid;
}

.handicap-detail-summary thead, .handicap-detail-history thead {
    background-color: #911414;
    color: #ffe600;
}

.handicap-detail-summary th, .handicap-detail-history th {
    padding: 4px;
    font-size: 11pt;
    font-weight: 500;
    padding-left: 10px;
}

.handicap-detail-summary, .handicap-detail-history {
    background-color: #d82c2c;
}

.handicap-detail-summary td {
    padding: 10px;
    color: #fff;
    font-size: 15pt;
    font-weight: 400;
}

.handicap-detail h3
{
    margin-top: 25px;
    font-size: 17pt;
}

.handicap-detail-summary .handicap-bold {
    font-size: 20pt;
    font-weight: 500;
    width: 128px;
}

.handicap-detail-history{
    min-width: 70%;
    border: #440000 1px solid;
}

.handicap-detail-history td {
    padding: 10px;
    color: #fff;
    font-size: 13pt;
    font-weight: 400;
}

.handicap-detail-history tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.08);
}

.handicap-detail-history .handicap-delete{
    width: 60px;
}

.handicap-detail-history .handicap-move{
    width: 60px;
}

.handicap-detail-history .handicap-delete a{
    text-decoration: none;
    color: #ffe600;
}

.handicap-detail-history .handicap-flag {
    padding-left: 15px;
    padding-right: 15px;
}

.handicap-detail-history .handicap-date {
    width: 180px;
}

.handicap-detail-history .handicap-score {
    font-size: 20pt;
    font-weight: 500;
    width: 60px;
}

.handicap-detail-history .no-counter {
    opacity: 0.5;
    background-color: rgba(0,0,0,1)!important;
}


@media print {
    /* All your print styles go here */
    .nav-header,
    .nav-sub-header,
    button,
    .footer
    {
      display: none !important;
    }

    .handicap-table{
        border: none!important;
        color: #000!important;
    }

    .handicap-table a{
        color: #000!important;
    }

    .handicap-table .member-name{
        width: 50%!important;
    }

    .handicap-table .member-discipline{
        width: 60%!important;
    }

    .handicap-table .member-handicap{
        width: 10%!important;
    }

    .handicap-row
    {
        border-bottom: 2px solid #000!important;
    }

    body{
        height: auto!important;
    }
  }