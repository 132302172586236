.venue-container
{
    min-width: 400px;
    margin-bottom: 30px;
}

.venue-container table
{
    width: 100%;
}

.venue-container table thead
{
    background-color:#962424;
}

.venue-container table thead th
{
    font-weight: 500;
    font-size: 13pt;
    padding-left: 10px;
}

.venue-container table td
{
    font-weight: 400;
    font-size: 12pt;
    padding-left: 10px;
}

.grade-template-class-table
{
    width: 100%;
}

.grade-template-class-table td
{
    width: 180px;
}