
.event-day-container, .event-match-container, .event-round-container
{
    border: 1px solid #000;
    padding: 12px;
}

.event-day-container
{
    margin-top: 20px;
}

.event-match-container
{
    margin-top: 12px;
}

.event-round-container
{
    margin-top: 6px;
}

.event-match-container .nav-tabs
{
    margin-top: 15px;
}

.event-match-container .nav
{
    --bs-nav-link-color: #490b0b;
    --bs-nav-link-hover-color: #490b0b;
    --bs-nav-link-disabled-color: grey;
}

.event-match-title, .event-day-title, .event-round-title, .event-event-title
{
    display: inline;
    padding-inline-start: 2px;
    padding-inline-end: 25px;
}

.event-competitor-title
{
    display: inline;
    padding-inline-start: 2px;
    padding-inline-end: 25px;
}

.event-competitor-title :is(h1,h2,h3,h4,h5,h6)
{
    display: inline;
}

.event-competitor-title-bar
{
    margin-top: 30px;
}

.score-table {
    margin-top: 5px;
}

.shot-score-row
{
    margin-top: 8px;
}

.round-total
{
    margin-top: 6px;
    font-size: 16pt;
}

.match-aggregate-total{
    font-size: 20pt;
}

/* .event-title-bar-button
{
    
} */

.event-title-bar-button button
{
    margin: 5px;
    margin-left: 10px;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}

/* .event-title-bar
{
    height: 38px;
} */